import React from 'react'

import appstore from '../img/app-store.svg'
import googleplay from '../img/google-play.svg'

import appstorelight from '../img/app-store-light.svg'
import googleplaylight from '../img/google-play-light.svg'

export default ({ light }) => {
  return (
    <div>
      <figure
        className="image"
        style={{ display: 'inline-block', marginRight: 10 }}
      >
        <a
          className="image-link"
          href="https://itunes.apple.com/us/app/safestart-medical/id1178744508?ls=1&mt=8"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={light ? appstorelight : appstore}
            alt="SafeStart"
            width="176"
          />
        </a>
      </figure>
      <figure className="image" style={{ display: 'inline-block' }}>
        <a
          className="image-link"
          href="https://play.google.com/store/apps/details?id=com.safestartmedical.safestart"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={light ? googleplaylight : googleplay}
            alt="SafeStart"
            width="176"
          />
        </a>
      </figure>
    </div>
  )
}
