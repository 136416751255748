import React from 'react'

const Banner = props => (
  <section>
    <div
      className="full-width-image-container-inner"
      style={
        {
          // backgroundImage: `url(${props.image})`,
        }
      }
    >
      <h2
        className="has-text-weight-bold is-size-1"
        style={{
          border: '5px solid #fff',
          color: 'white',
          padding: '1rem',
          position: 'absolute',
          bottom: '50px'
        }}
      >
        {props.title}
      </h2>
    </div>
  </section>
)

export default Banner
