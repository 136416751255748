import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import ScrollAnimation from 'react-animate-on-scroll'

import logo from '../img/logo.svg'
import AppLinks from './AppLinks'

const Footer = () => (
  <div>
    <section
      className="bg-primary"
      style={{
        paddingTop: 40,
        paddingBottom: 40,
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
      }}
    >
      <Container>
        <Row>
          <Col xs="12" sm="8">
            <h3>SafeStart works with or without an EMR</h3>
          </Col>
          <Col xs="12" sm="4" className="text-center">
            <ScrollAnimation animateIn="pulse">
              <Button size="lg" color="light" href="/contact/">
                Request a Demo
              </Button>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </section>

    <footer className="is-dark">
      <Container>
        <Row>
          <Col sm="12" md="4">
            <img src={logo} alt="SafeStart Logo" className="space-below" />
            <p>222 West Merchandise Mart Plaza Suite 1230 Chicago, IL 60654</p>

            <h3 className="title">Follow Us</h3>
            <ul className="list-unstyled footer-social">
              <li>
                <a
                  href="https://web.facebook.com/SafeStart-Medical-769286666495337/"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="SafeStart Facebook"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/safestartmed"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="SafeStart Twitter"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/10692304/"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="SafeStart Linkedin"
                >
                  Linkedin
                </a>
              </li>
            </ul>

            <h3 className="title">Download Apps</h3>

            <AppLinks light />
          </Col>
          <Col sm="12" md="3">
            <h3 className="title">Who We Serve</h3>
            <ul className="list-unstyled">
              <li>
                <a href="/hospitals/">Hospitals</a>
              </li>
              <li>
                <a href="/pediatric-surgery/">Pediatric Surgery</a>
              </li>
              <li>
                <a href="/surgery-centers/">Surgery Centers</a>
              </li>
            </ul>
          </Col>
          <Col sm="12" md="3">
            <h3 className="title">Resources</h3>
            <ul className="list-unstyled">
              <li>
                <a href="/news/">News</a>
              </li>
              <li>
                <a href="/videos/">Videos</a>
              </li>
            </ul>
          </Col>
          <Col sm="12" md="2">
            <h3 className="title">Company</h3>
            <ul className="list-unstyled">
              <li>
                <a href="/about/">About</a>
              </li>
              <li>
                <a href="/contact/">Contact</a>
              </li>
              <li>
                <a href="/privacy-policy/">Privacy Policy</a>
              </li>
              <li>
                <a href="/terms-use/">Terms of Use</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  </div>
)

export default Footer
