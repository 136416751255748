import React from 'react'

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

import logo from '../img/logo.svg'

export default class AppNavbar extends React.Component {
  state = {
    menuActive: false,
    scrollListener: null,
    whoWeServeMenuActive: false,
    resourcesMenuActive: false,
    navState: 'normal'
  }

  componentDidMount() {
    const scrollListener = document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 50
      if (isTop) {
        this.setState({
          navState: 'normal'
        })
      } else {
        this.setState({
          navState: 'shrink'
        })
      }
    })

    this.setState({
      scrollListener: scrollListener
    })
  }

  isActive = page => {
    /*
    const pathName = this.props.location.pathname
    if (page === '/' && pathName === '/' && pathName.length === 1) {
      return true
    }

    if (page === 'who-we-serve') {
      const pages = ['/hospitals/', '/pediatric-surgery/', '/surgery-centers/']
      return pages.indexOf(pathName) > -1
    }

    if (page === 'resources') {
      const pages = ['/videos/', '/news/']
      return pages.indexOf(pathName) > -1
    }

    if (page !== '/' && pathName.includes(page)) {
      return true
    }
    */

    return false
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.state.scrollListener)
  }

  toggleMenu = () => {
    this.setState({
      menuActive: !this.state.menuActive
    })
  }

  render() {
    const navbarPadding = this.state.navState === 'shrink' ? 4 : 20
    return (
      <div>
        <Navbar
          color="primary"
          dark
          expand="lg"
          fixed="top"
          style={{ paddingTop: navbarPadding, paddingBottom: navbarPadding }}
        >
          <Container>
            <NavbarBrand href="/">
              <figure className="image" style={{ height: 20 }}>
                <img src={logo} alt="SafeStart" />
              </figure>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleMenu} />

            <Collapse isOpen={this.state.menuActive} navbar>
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <NavLink
                    href="/"
                    className={this.isActive('/') ? 'nav-active' : ''}
                  >
                    Home
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle
                    nav
                    caret
                    className={
                      this.isActive('who-we-serve') ? 'nav-active' : ''
                    }
                  >
                    Who We Serve
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem href="/hospitals/">Hospitals</DropdownItem>
                    <DropdownItem href="/pediatric-surgery/">
                      Pediatric Surgery
                    </DropdownItem>
                    <DropdownItem href="/surgery-centers/">
                      Surgery Centers
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink
                    href="/blog/"
                    className={this.isActive('/blog/') ? 'nav-active' : ''}
                  >
                    Blog
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="/about/"
                    className={this.isActive('/about/') ? 'nav-active' : ''}
                  >
                    About
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown
                  nav
                  inNavbar
                  className={this.isActive('resources') ? 'nav-active' : ''}
                >
                  <DropdownToggle nav caret>
                    Resources
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem href="/videos/">Videos</DropdownItem>
                    <DropdownItem href="/news/">News</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink
                    href="/contact/"
                    className={this.isActive('/contact/') ? 'nav-active' : ''}
                  >
                    Contact
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink
                    href="https://api2.safestartmedical.com/"
                    target="_blank"
                  >
                    Login
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="button cta-button" href="/contact/">
                    Request a Demo
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    )
  }
}
