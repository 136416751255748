import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Content, { HTMLContent } from '../components/Content'
import bannerImage from '../img/contact-bg.jpg'
import Banner from '../components/Banner'
import Layout from '../components/Layout'

const Video = ({ id }) => {
  const url = 'https://player.vimeo.com/video/' + id
  return (
    <div className="front-video">
      <iframe
        src={url}
        width="640"
        height="360"
        // prettier-ignore
        frameBorder="0"
        // prettier-ignore
        allowFullScreen
      />
    </div>
  )
}

const VideosPage = ({}) => {
  let videos = ['224414725', '224414969']

  const renderedVideos = videos.map((id, count) => (
    <Col sm="12" md="6" className="space-below" key={count}>
      <Video id={id} />
    </Col>
  ))

  return (
    <Layout>
      <Banner image={bannerImage} title={'Videos'} />
      <section className="padded-section">
        <Container>
          <Row>{renderedVideos}</Row>
        </Container>
      </section>
    </Layout>
  )
}

export default VideosPage
